import { NavLink, useHistory } from "react-router-dom";
import { formatMifosDate, formatMoney } from "../../helpers/formatter";
import Loader from "../Loader/Loader.component";
import { useState } from "react";
import { appInsights } from "../AppInsight/AppInsight";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../config";
import { token } from "../../apis/dotNetApiMethods";

export default function LoanDetail({
  loanDetails,
  loanDetailsSummary,
  dataForRepayment,
  loadingMifos,
  error,
  profileDetails,
  loanStatus,
  borrowerId,
  loanId,
}) {
  const history = useHistory();
  const [downloading, setDownloading] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  if (loadingMifos)
    return (
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    );
  if (error)
    return <h5 className="alert alert-danger small">An error occured</h5>;

  const loanDetailIsActive = Object.keys(loanDetails).length !== 0;
  const affixCurrency =
    loanDetails &&
    loanDetails.summary &&
    loanDetails.summary.currency &&
    loanDetails.summary.currency.code;

  const handleDownloadStatement = async () => {
    setDownloading(true);
    fetch(
      CONFIG.BASE_URL +
        `${apiEndpoints.DOWNLOAD_LOAN_STATEMENT}?loan_ref=${loanDetails?.externalId}&format=pdf`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token(),
        },
        // body: JSON.stringify(data)
      }
    )
      .then((response) => {
        setDownloading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Loan Statement.pdf`);
            link.click();
            // link.parentNode.removeChild(link);
          });
        }

        if (response.status === 400) {
          setDownloading(false);
          // response.json()
          // .then(res => {
          //     this.setState({ error: res.message });
          // })
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          setDownloading(false);
          setErrorOccurred(true);
          setTimeout(() => {
            setErrorOccurred(false);
          }, 3000);
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error) => {
        setErrorOccurred(true);
        setTimeout(() => {
          setErrorOccurred(false);
        }, 3000);
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "LoanDetail.component.tsx",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloading(false);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between pb-4">
        {loanDetails && loanDetails.externalId ? (
          <p className="h5">
            Loan {loanDetails ? loanDetails.externalId : ""} Details
          </p>
        ) : (
          <p className="h5"></p>
        )}
        <div>
          <button
            className="btn advancly-white-btn btn-sm transition-3d-hover mr-3"
            onClick={handleDownloadStatement}
          >
            {downloading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              "Download Statement"
            )}
          </button>
          <NavLink
            to={{
              pathname: `/repayments/repay/`,
              data: { dataForRepayment, loanDetailsSummary },
            }}
          >
            <button
              className="btn btn-sm advancly-btn"
              style={{ cursor: "pointer" }}
              disabled={
                ((!loanDetails || Object.keys(loanDetails).length === 0) &&
                  !dataForRepayment) ||
                loanStatus.toLowerCase() !== "active"
              }
            >
              Repay
            </button>
          </NavLink>
        </div>
      </div>

      {errorOccurred && (
        <p className="alert alert-danger col-md-6">
          An error occurred while downloading statement
        </p>
      )}

      <div className="row">
        {profileDetails.borrower_type_value === "Corporate" ? (
          <div className="col-12 col-md-4">
            <label className="label-txt">Business Full Name</label>
            <div className="form-group">
              <input
                type="text"
                className="user__form form-control"
                readOnly
                value={
                  loanDetails && loanDetailIsActive
                    ? loanDetails.clientName
                    : "n/a"
                }
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {profileDetails.borrower_type_value === "Corporate" ? (
          <div className="col-12 col-md-4 ">
            <label className="label-txt">Product Name</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                readOnly
                name="cardNumber"
                className="user__form form-control"
                value={
                  loanDetails && loanDetailIsActive
                    ? loanDetails.loanProductName
                    : "n/a"
                }
              />
            </div>
          </div>
        ) : (
          <div className="col-12 col-md-6">
            <label className="label-txt">Product Name</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                readOnly
                name="cardNumber"
                className="user__form form-control"
                value={
                  loanDetails && loanDetailIsActive
                    ? loanDetails.loanProductName
                    : "n/a"
                }
              />
            </div>
          </div>
        )}
        {profileDetails.borrower_type_value === "Corporate" ? (
          <div className="col-12 col-md-4">
            <label className="label-txt">Interest (% per annum)</label>
            <div className="form-group">
              <input
                type="text"
                className="user__form form-control"
                readOnly
                value={
                  loanDetails && loanDetailIsActive
                    ? loanDetails.annualInterestRate
                    : "n/a"
                }
              />
            </div>
          </div>
        ) : (
          <div className="col-12 col-md-6">
            <label className="label-txt">Interest (% per annum)</label>
            <div className="form-group">
              <input
                type="text"
                className="user__form form-control"
                readOnly
                value={
                  loanDetails && loanDetailIsActive
                    ? loanDetails.annualInterestRate
                    : "n/a"
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12 col-md-4 ">
          <label className="label-txt">Loan Amount (Principal)</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={
                loanDetails && loanDetailIsActive
                  ? formatMoney(
                      loanDetailsSummary.principalDisbursed,
                      affixCurrency
                    )
                  : "n/a"
              }
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label className="label-txt">Interest Amount</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={
                loanDetails && loanDetailIsActive
                  ? formatMoney(
                      loanDetailsSummary.interestCharged,
                      affixCurrency
                    )
                  : "n/a"
              }
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label className="label-txt">Loan Repay Amount</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={
                loanDetails && loanDetailIsActive
                  ? formatMoney(
                      loanDetailsSummary.totalExpectedRepayment,
                      affixCurrency
                    )
                  : "n/a"
              }
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-4 ">
          <label className="label-txt">Outstanding Principal</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={
                loanDetails && loanDetailIsActive
                  ? formatMoney(
                      loanDetailsSummary.principalOutstanding,
                      affixCurrency
                    )
                  : "n/a"
              }
            />
          </div>
        </div>
        <div className="col-12 col-md-4 ">
          <label className="label-txt">Outstanding Interest</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={
                loanDetails && loanDetailIsActive
                  ? formatMoney(
                      loanDetailsSummary.interestOutstanding,
                      affixCurrency
                    )
                  : "n/a"
              }
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label className="label-txt">Current Debt</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={
                loanDetails && loanDetailIsActive
                  ? formatMoney(
                      loanDetailsSummary.totalOutstanding,
                      affixCurrency
                    )
                  : "n/a"
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 ">
          <label className="label-txt">Loan Tenure (in days)</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={
                loanDetails && loanDetailIsActive
                  ? loanDetails.termFrequency
                  : "n/a"
              }
            />
          </div>
        </div>

        <div className="col-12 col-md-4 ">
          <label className="label-txt">Repayment Frequency </label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={
                loanDetails &&
                loanDetailIsActive &&
                loanDetails.termPeriodFrequencyType
                  ? loanDetails.termPeriodFrequencyType.value
                  : "n/a"
              }
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label className="label-txt">Schedule Type</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={
                loanDetails && loanDetailIsActive && loanDetails.interestType
                  ? loanDetails.interestType.value
                  : "n/a"
              }
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-4 ">
          <label className="label-txt">Disbursed Date</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={
                loanDetails && loanDetailIsActive
                  ? formatMifosDate(loanDetails.timeline.approvedOnDate)
                  : "n/a"
              }
            />
          </div>
        </div>
        <div className="col-12 col-md-4 ">
          <label className="label-txt">Expected Maturity Date</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              name="cardNumber"
              className="user__form form-control"
              value={
                loanDetails && loanDetailIsActive
                  ? formatMifosDate(loanDetails.timeline.expectedMaturityDate)
                  : "n/a"
              }
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label className="label-txt">Status</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={
                loanDetails && loanDetailIsActive
                  ? loanDetails.status.active === true
                    ? "ACTIVE"
                    : loanDetails.status.pendingApproval === true
                    ? "Pending"
                    : !loanDetails.status.pendingApproval &&
                      !loanDetails.status.closed
                    ? "Denied"
                    : loanDetails.status.closed
                    ? "Closed"
                    : "n/a"
                  : "n/a"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
